import React, { useEffect, Fragment } from "react";
import CTA from "../general/cta";
import EntryIllustration from "../../../../assets/img/overdrafts-landing.inline.svg";
import { scrollToElement } from "../../../utility/utils";
import BelowFoldCards from "../general/belowFoldCards";
import BadgesEntryFold from "../general/badgesEntryFold";
import WithdrawIcon from "../../../../assets/img/withdrawIcon.inline.svg"
import NairaIcon from "../../../../assets/img/naira.inline.svg"
import PercentageIcon from "../../../../assets/img/percentage-blue.inline.svg"
import FirstSectionImage from "../../../../assets/img/kuda-overdrafts-illustration.inline.svg"
import SecondSectionImage from "../../../../assets/img/account-app-balance.inline.svg"
import ThirdSectionImage from "../../../../assets/img/overdraft-summary.inline.svg"
import ImageTextRight from "../general/imageTextRight";
import ImageTextLeft from "../general/imageTextLeft";
import FAQList from "../general/faqsList";
import MoreForYouComponent from "../general/more-for-you";

import BorrowIcon from '../../../../assets/img/more-for-you/kuda-borrow-icon.inline.svg'
import TransferIcon from '../../../../assets/img/more-for-you/kuda-transfer.inline.svg'
import KudaSaveIcon from '../../../../assets/img/more-for-you/kuda-save-icon.inline.svg'

const entryContent = {
    title: "Get a chance to qualify for an overdraft when you use your Kuda account actively.",
    subtitle: "With a Kuda overdraft, you can spend above your Kuda account balance up to a limit at a low interest rate of 0.6%",
    illustration: <EntryIllustration />
}
const kudaTopFeatures = [
    {
        icon: <WithdrawIcon />,
        text: "Get money for your urgent needs easily.",
    },
    {
        icon: <NairaIcon />,
        text: "Spend above your account balance.",
    },
    {
        icon: <PercentageIcon />,
        text: "Take an overdraft at a low daily interest rate of 0.6%.",
    },
]
const firstSection = {
    title: "Get money for urgent needs easily without paperwork.",
    subtitle: "Using your Kuda account as your main bank account gives you an automatic chance to qualify for a Kuda overdraft.",
    url: "https://help.kuda.com/support/solutions/articles/73000560757-overdrafts-faqs/",
    name: " Learn More About Overdrafts",
    illustration: <FirstSectionImage />,
}
const secondSection = {
    title: "Spend above your account balance whenever you need to.",
    subtitle: "A Kuda overdraft is extra money you can use any time you don’t have enough in your account.",
    url: "https://kuda.onelink.me/abUI/344e3dde",
    name: "Download Kuda",
    illustration: <SecondSectionImage />,
}
const thirdSection = {
    title: "Pay back your overdraft with just 0.6% daily interest.",
    subtitle: "Overdrafts are easy to take and paying back is as easy as adding the amount due to your Kuda account.",
    url: "https://kuda.onelink.me/abUI/344e3dde",
    name: "Download Kuda",
    illustration: <ThirdSectionImage />,
}

const questions = [
    {
        heading: "How do I qualify for a Kuda overdraft?",
        list: (
            <span className="flex flex-column inApp-contact-ways--wrap">
                <span className="faq-description f-16">For a chance to qualify for a Kuda overdraft,
                    use your Kuda account actively as your main bank account.
                </span>

            </span>),
        index: 1
    },
    {
        heading: "How much overdraft can I take?",
        list: (
            <span className="flex flex-column inApp-contact-ways--wrap">
                <span className="faq-description f-16">If you qualify for an overdraft, we’ll show you the maximum amount you can take on your Kuda app.
                </span>

            </span>),
        index: 2
    },
    {
        heading: "Can I change my overdraft limit?",
        list: (
            <span className="flex flex-column inApp-contact-ways--wrap">
                <span className="faq-description f-16">Yes, you can decrease or increase how much overdraft you can take up to the maximum amount available on your Kuda app. </span>

            </span>),
        index: 3
    },
    {
        heading: "What’s the difference between an overdraft and a loan?",
        list: (
            <span className="flex flex-column inApp-contact-ways--wrap">
                <span className="faq-description f-16">An overdraft is different from a loan in that it isn't a fixed amount of money - you can spend above your account balance up to a limit. And unlike interest on a loan which is charged monthly, interest on an overdraft is charged daily. </span>

            </span>),
        index: 4
    },

]

const moreForYou = [

    {
        icon: <BorrowIcon />,
        title: "Loans",
        subText: "Get instant loans up to ₦150,000 in the Kuda loan app easily in Nigeria without paperwork.",
        linkTo: `/en-ng/personal-loan/`
    },
    {
        icon: <KudaSaveIcon />,
        title: "Save",
        subText: `Make saving easier with Spend+Save, Pocket or Fixed savings with up to 12% annual interest.`,
        linkTo: `/en-ng/save/`
      },
    {
        icon: <TransferIcon />,
        title: "Transfer & Spend",
        subText: "Send money for free to any Nigerian account with 25 free transfers every month.",
        linkTo: `/en-ng/spend/`
    },

]

const Overdrafts = () => {

    useEffect(() => {
        scrollToElement();
        window.addEventListener("scroll", function () {
            scrollToElement();
        });
    }, [])

    return (
        <Fragment>
            <BadgesEntryFold
                title={entryContent.title}
                subtitle={entryContent.subtitle}
                illustration={entryContent.illustration}
            />
            <BelowFoldCards topFeatures={kudaTopFeatures} />
            <ImageTextRight
                title={firstSection.title}
                subtitle={firstSection.subtitle}
                illustration={firstSection.illustration}
                name={firstSection.name}
                url={firstSection.url}
            />
            <ImageTextLeft
                title={secondSection.title}
                subtitle={secondSection.subtitle}
                illustration={secondSection.illustration}
                name={secondSection.name}
                url={secondSection.url}
            />
            <ImageTextRight
                title={thirdSection.title}
                subtitle={thirdSection.subtitle}
                illustration={thirdSection.illustration}
                name={thirdSection.name}
                url={thirdSection.url}
            />
            <FAQList title={"Overdraft FAQs"} questions={questions} />
            <MoreForYouComponent moreForYou={moreForYou} />
            <CTA />
        </Fragment>
    )
}

export default Overdrafts;
